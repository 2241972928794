import { useState, useEffect } from 'react'
import { convertRemToPixels } from '../utils'

const useResizeScreen = (data: { pixels?: number }) => {
  const { pixels = 0 } = data
  const [height, setHeight] = useState(
    window.innerHeight - convertRemToPixels(pixels)
  )

  function debounce(func: any) {
    var timer: any
    return function (event: any) {
      if (timer) clearTimeout(timer)
      timer = setTimeout(func, 100, event)
    }
  }

  useEffect(() => {
    window.addEventListener(
      'resize',
      debounce(function (e: any) {
        setHeight(window.innerHeight - convertRemToPixels(pixels))
      })
    )
  }, [])

  return { height }
}

export default useResizeScreen
