import { Checkbox } from 'antd'
import './index.scss'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

interface SelectRenderProps {
  isCheck?: boolean
  onChangeChecked?: (val: CheckboxChangeEvent) => void
  isMainLine?: boolean
  screenColor?: 'blue' | 'gray' | 'grayer'
}

const SelectRender = (props: SelectRenderProps) => {
  return (
    <Checkbox
      disabled={!props.isMainLine}
      checked={props.isCheck}
      onChange={props.onChangeChecked}
      className={`${
        props.screenColor === 'blue'
          ? 'checkbox-blue'
          : props.screenColor === 'gray'
          ? 'checkbox-gray'
          : 'checkbox-gray checkbox-grayer'
      } flex justify-center`}
    />
  )
}

export default SelectRender
