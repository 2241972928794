import { MouseEventHandler } from 'react'
import CustomDropdown, {
  DropdownType,
} from '../../../../components/Common/CustomDropdown/CustomDropdown'
import './ActionRow.scss'
import clsx from 'clsx'

interface PropsNewActionRow {
  onClickDown?: MouseEventHandler
  onClickUp?: MouseEventHandler
  onClickPlus?: MouseEventHandler
  isClickUp?: boolean
  isDisableAdd?: boolean
  isDisplayActionDropdown?: boolean
  isDisableActionButton?: boolean
  isHideExport?: boolean
}

const defaultFunction = function () {}

const NewActionRow = (props: PropsNewActionRow) => {
  const {
    onClickUp = defaultFunction,
    onClickDown = defaultFunction,
    onClickPlus = defaultFunction,
    isClickUp = true,
    isDisableAdd,
    isDisplayActionDropdown = false,
    isDisableActionButton = false,
    isHideExport = false,
  } = props

  return (
    <div
      className={`my-4 ml-10 flex justify-between action-row ${
        !isHideExport && 'mr-10'
      }`}
    >
      <CustomDropdown
        className={`${
          isDisplayActionDropdown ? 'block' : 'hidden'
        } action-dropdown`}
        placeholder="Actions"
        width="w-36"
        type={DropdownType.PRIMARY}
        options={[
          {
            value: 'Editer',
            label: (
              <div className="flex items-center border-gray1 border-b border-solid border-0 pb-3">
                <i className="dropdown-icon icon-editor "></i>
                Editer
              </div>
            ),
          },
          {
            value: 'Historique',
            label: (
              <div className="flex items-center border-gray1 border-b border-solid border-0 pb-3">
                <i className="dropdown-icon icon-history"></i>
                Historique
              </div>
            ),
          },
          {
            value: 'Imprimer',
            label: (
              <div className="flex items-center border-gray1 border-b border-solid border-0 pb-3">
                <i className=" dropdown-icon icon-printer"></i>
                Imprimer
              </div>
            ),
          },
          {
            value: 'Supprimer',
            label: (
              <div className="flex items-center text-red pb-2">
                <i className="dropdown-icon icon-delete"></i>
                Supprimer
              </div>
            ),
          },
        ]}
      />
      <div className="flex ml-5">
        {!isHideExport && isClickUp && (
          <div
            className="rounded-md mr-1 h-10 w-11 flex items-center justify-center"
            onClick={onClickUp}
          >
            <i className="icon-export w-full h-full"></i>
          </div>
        )}
      </div>
      <div
        className={clsx(
          'rounded-md mr-1 h-10 w-11 flex items-center justify-center',
          !isDisableAdd ? 'cursor-pointer' : 'rounded-md'
        )}
        onClick={onClickDown}
      >
        <i className="icon-import w-full h-full"></i>
      </div>
      <div
        className={clsx(
          'rounded-md h-10 w-11 flex items-center justify-center',
          !isDisableAdd ? 'cursor-pointer' : 'rounded-md'
        )}
        // @ts-ignore
        onClick={() => !isDisableAdd && onClickPlus()}
      >
        <i
          className={clsx(
            'w-full h-full',
            isDisableAdd ? 'icon-add' : 'icon-add-active'
          )}
        ></i>
      </div>
    </div>
  )
}

export default NewActionRow
